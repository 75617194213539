import AlmostThereImage from "./AlmostThere.png";
import GoodToKnowImage from "./GoodToKnow.png";
import HaveFunImage from "./HaveFun.png";
import LearningBetterImage from "./LearningBetter.png";
import LifeSkillsImage from "./LifeSkills.png";
import LotsOfActivitiesImage from "./LotsOfActivities.png";
import PlayToLearnImage from "./PlayToLearn.png";
import WelcomeToOurWebsiteImage from "./WelcomeToOurWebsite.png";
import WithLoveImage from "./WithLove.png";

const AlmostThere = {
  url: AlmostThereImage,
  alt: "Almost there",
};

const GoodToKnow = {
  url: GoodToKnowImage,
  alt: "Good to know",
};

const HaveFun = {
  url: HaveFunImage,
  alt: "Have fun",
};

const LearningBetter = {
  url: LearningBetterImage,
  alt: "Learning better",
};

const LifeSkills = {
  url: LifeSkillsImage,
  alt: "Life skills",
};

const LotsOfActivities = {
  url: LotsOfActivitiesImage,
  alt: "Lots of activities",
};

const PlayToLearn = {
  url: PlayToLearnImage,
  alt: "Play to learn",
};

const WelcomeToOurWebsite = {
  url: WelcomeToOurWebsiteImage,
  alt: "Welcome to our website",
};

const WithLove = {
  url: WithLoveImage,
  alt: "With love",
};

export {
  AlmostThere,
  GoodToKnow,
  HaveFun,
  LearningBetter,
  LifeSkills,
  LotsOfActivities,
  PlayToLearn,
  WelcomeToOurWebsite,
  WithLove,
};
